/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import AdminDashboard from '@components/layouts/MainContainer';
import { Card, Loading } from '@/components/elements';
import { FaReply } from "react-icons/fa6";
import { useAuthContext } from '@/context';

const Dashboard = () => {
  const [userProfile, setUserProfile] = useState<any>(null); // NOSONAR
  const [loading, setLoading] = useState<boolean>(false);
  const { auth, saveUser } = useAuthContext();
  
  useEffect(() => {
    setLoading(true);
    if (auth && saveUser) {
      const userParse = saveUser;
      setUserProfile(userParse?.full_name || JSON.parse(userParse).full_name);
      setLoading(false);
    }
  }, [auth, saveUser, userProfile]);

  if (loading) {
    return <Loading />;
  }

  if (!auth) {
    window.location.replace('/login');
  }

  return (
    <AdminDashboard>
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-dashboard lg:py-0">
        <Card className="bg-white text-center lg:py-8 lg:px-16" bodyClassName="space-y-8">
          <h4 className="text-7xl" data-testid="home-text">ยินดีต้อนรับ</h4>
          {userProfile && <h2 className="text-4xl font-normal">{userProfile}</h2>}
          <p className="text-4xl">เข้าสู่ระบบหลังบ้านของ Samathi101</p>
          <p className="text-welcome">ที่นี่คุณสามารถสร้างและจัดการเนื้อหาต่างๆ ของเว็บไซนต์ได้จากเมนูซ้ายมือ</p>
          <FaReply className='text-5xl mx-auto scale-y-[-1] text-welcome' />
        </Card>
      </div>
    </AdminDashboard>
  );
};

export default Dashboard;
